
























































































































import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import WarnCommandHandlerModuleBuilder from "@/includes/logic/Modules/models/modules/WarnCommandHandlerModule/WarnCommandHandlerModuleBuilder";
import { InputSetups } from "@/mixins/input-setups";
import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import FilterResetPunishSettings from "@/components/Modules/components/filters/FilterResetPunishSettings.vue";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import FilterJournal from "@/components/Modules/components/filters/FilterJournal.vue";

import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    FilterResetPunishSettings,
    PunishActionSetting,
    NumberInput
  }
})
export default class WarnCommandHandlerModuleView extends Mixins(InputSetups, Placeholders, ModuleBindings) {
  @VModel() module!: WarnCommandHandlerModuleBuilder
}
