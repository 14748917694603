var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"warn-command-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'notice_before_ban_count',
          'hasAnchor': true,
          'min': 0,
          'settingWrapper': {
            disabledValue: 0,
            hasFieldButton: true,
            customiseValue: 3
          }
        }
      }}}),(_vm.module.model.params.notice_before_ban_count > 0)?_c('punish-action-setting',{staticClass:"punish-action-nested",attrs:{"title-key":"warning_punish_action","message-editor-placeholders":_vm.WARN_SYSTEM_PLACEHOLDERS},model:{value:(_vm.module.model.params.warning_punish_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "warning_punish_action", $$v)},expression:"module.model.params.warning_punish_action"}}):_vm._e(),_c('punish-action-setting',{attrs:{"title-key":((_vm.module.model.snakeCaseType) + "_ban_punish_action"),"message-editor-placeholders":_vm.WARN_SYSTEM_PLACEHOLDERS},model:{value:(_vm.module.model.params.ban_punish_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "ban_punish_action", $$v)},expression:"module.model.params.ban_punish_action"}}),_c('a-divider'),_c('number-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'take_away_reputation',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 1,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'take_away_xp',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 1,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}})],1),_c('a-card',{staticClass:"filter-reset-punish-settings mt-5",attrs:{"title":_vm.$t('filter_subsetting_accordion_reset_punish')}},[_c('filter-reset-punish-settings',{attrs:{"allowed-interval":_vm.module.model.params.allowed_interval,"allowed-interval-round":_vm.module.model.params.allowed_interval_round,"fields-prefix":_vm.module.model.snakeCaseType},on:{"update:allowedInterval":function($event){return _vm.$set(_vm.module.model.params, "allowed_interval", $event)},"update:allowed-interval":function($event){return _vm.$set(_vm.module.model.params, "allowed_interval", $event)},"update:allowedIntervalRound":function($event){return _vm.$set(_vm.module.model.params, "allowed_interval_round", $event)},"update:allowed-interval-round":function($event){return _vm.$set(_vm.module.model.params, "allowed_interval_round", $event)}}})],1),_c('a-card',{staticClass:"mt-5"},[_c('punish-action-setting',{attrs:{"title-key":((_vm.module.model.snakeCaseType) + "_first_message_punish_action"),"message-editor-placeholders":_vm.WARN_SYSTEM_PLACEHOLDERS},model:{value:(_vm.module.model.params.first_message_punish_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "first_message_punish_action", $$v)},expression:"module.model.params.first_message_punish_action"}})],1),_c('filter-journal',{attrs:{"warn-field":_vm.module.model.params.journal_moderation_warn_warn,"ban-field":_vm.module.model.params.journal_moderation_ban_warn},on:{"update:warnField":function($event){return _vm.$set(_vm.module.model.params, "journal_moderation_warn_warn", $event)},"update:warn-field":function($event){return _vm.$set(_vm.module.model.params, "journal_moderation_warn_warn", $event)},"update:banField":function($event){return _vm.$set(_vm.module.model.params, "journal_moderation_ban_warn", $event)},"update:ban-field":function($event){return _vm.$set(_vm.module.model.params, "journal_moderation_ban_warn", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }